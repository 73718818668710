<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
import Vue from "vue";
import moment from "moment";
/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "USER DETAILS",
      userData:"",
      createdDate:'',
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "B2B USERS",
          href: "/users"
        },
        {
          text: "USERS DETAILS",
          active: true
        }
      ],
    };
  },
  mounted() {
    Master.userDetails({
      userId: localStorage.getItem('userId')
    }).then((res) => {
      console.log(res)
      this.userData = res.data.data;
      this.createdDate = moment(this.userData.createdAt).format("DD-MM-YYYY HH:ss:mm")
    })
  },
  methods: {
    /**
     * Selected image shows
     */
    async approve(){
      Master.approveUsers({
        ids: [localStorage.getItem('userId')],
        verificationStatus: 1
      }).then((res) => {
        // this.successMessage = res.data.message;
        Vue.swal({
          position: "center",
          icon: "success",
          title: ""+res.data.message+"",
          showConfirmButton: false,
          timer: 1500
        });
        Master.userDetails({
          userId: localStorage.getItem('userId')
        }).then((res) => {
          console.log(res)
          this.userData = res.data.data;
        })
      })
    },
    async bulkActive(){
        Master.updateUserStatus({
          ids: [localStorage.getItem('userId')],
          userStatus: 1
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.userDetails({
            userId: localStorage.getItem('userId')
          }).then((res) => {
            console.log(res)
            this.userData = res.data.data;
          })
        })
    },
    async bulkInactive(){
        Master.updateUserStatus({
          ids: [localStorage.getItem('userId')],
          userStatus: 0
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.userDetails({
            userId: localStorage.getItem('userId')
          }).then((res) => {
            console.log(res)
            this.userData = res.data.data;
          }).catch((err) => {
            console.log(err)
            });
        })
    },
    async disApprove(){
      Master.approveUsers({
        ids: [localStorage.getItem('userId')],
        verificationStatus: 2
      }).then((res) => {
        // this.successMessage = res.data.message;
        Vue.swal({
          position: "center",
          icon: "success",
          title: ""+res.data.message+"",
          showConfirmButton: false,
          timer: 1500
        });
        Master.userDetails({
          userId: localStorage.getItem('userId')
        }).then((res) => {
          console.log(res)
          this.userData = res.data.data;
        })
      })
    },
    backAction(){
        localStorage.removeItem('userId')
    }

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="font-size-14 mb-3" style="text-transform: uppercase !important;"><u>PERSONAL DETAILS</u></h5>
            <div class="row">
              <div class="col-md-3">
                <div class="mt-4">
                  <label style="color: black">FIRST NAME</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;"> {{userData.firstName}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>LAST NAME</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.lastName}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>MOBILE NUMBER</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;" v-if="userData.mobile">+{{userData.mobile}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>PHONE NUMBER</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.phone}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>DATE OF REGISTRATION</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{ this.createdDate }}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>REGISTER EMAIL</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.userEmail}}</p>
                </div>
              </div>
            </div>



          </div>
        </div>
        <div class="card">

          <div class="card-body">
            <h5 class="font-size-14 mb-3" style="text-transform: uppercase !important;"><u>{{ (userData.businessType === "BUSINESS") ? "BUSINESS DETAILS" : "INDIVIDUAL DETAILS" }}</u></h5>


            <div class="row" v-if="userData.businessType === 'BUSINESS'">
              <div class="col-md-3">
                <div class="mt-4">
                  <label>COMPANY NAME</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.companyName}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>CONTACT PERSON</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.contactPerson}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>COMPANY EMAIL</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.contactEmail}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>COMPANY MOBILE NUMBER</label>
                  <p class="font-size-12 mb-3"  style="text-transform: uppercase !important;"  v-if="userData.contactMobile">+{{ userData.contactMobile }} </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3" v-if="userData.businessType != 'BUSINESS'">
                <div class="mt-4">
                  <label>PROOF OF IDENTITY</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.proofOfIdenity}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>STREET ADDRESS LINE 1</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.address1}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>STREET ADDRESS LINE 2</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.address2}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>COUNTRY</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.countryName}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>STATE / PROVINCE</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.state}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>CITY</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.city}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>PO CODE</label>
                  <p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.poBox}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>STATUS</label>
                  <p class="font-size-12 mb-3"  style="text-transform: uppercase !important;" >
                    <label  v-if="userData.verificationStatus == 1">
                      <!--                      APPROVED-->
                      <span v-if="userData.userStatus == 1"
                            class="btn btn-success btn-sm"
                      >ACTIVE </span>
                      <span v-else class="btn btn-pink btn-sm"> IN ACTIVE </span>
                    </label>
                    <label class="btn btn-warning btn-sm" v-else-if="userData.verificationStatus == 0">
                      WAITING FOR APPROVAL
                    </label>
                    <label class="btn btn-danger btn-sm" v-else-if="userData.verificationStatus === 2">REJECTED</label>
                  </p>


                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <label>WEBSITE</label>
                  <a target="_blank" :href="userData.websiteUrl"><p class="font-size-12 mb-3" style="text-transform: uppercase !important;">{{userData.websiteUrl}}</p></a>
                </div>
              </div>
              <div class="col-md-3" v-if="userData.businessType === 'BUSINESS'">
                <div class="mt-4">
                  <a target="_blank" :href="userData.registrationDoc"><h5 class="font-size-14 mb-3" style="text-transform: uppercase !important;">REGISTRATION DOCUMENTS <i class="mdi mdi-file-download lg"></i></h5></a>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mt-4">
                  <a target="_blank" :href="userData.authorisationDoc"  v-if="userData.authorisationDoc != ''"><h5 class="font-size-14 mb-3" style="text-transform: uppercase !important;">AUTHORISATION DOCUMENTS <i class="mdi mdi-file-download lg"></i> </h5></a>
                </div>
              </div>
            </div>




          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-6">
                <div  align="center">
                  <div class="btn-toolbar">
                    <a href="javascript:void(0);" class="btn btn-outline-info mb-2 mr-1" @click="approve">
                      <i class="mdi mdi-account-check mr-2"></i> APPROVE
                    </a>
                    <a href="javascript:void(0);" class="btn btn-outline-danger mb-2 mr-1" @click="disApprove">
                      <i class="mdi mdi-account-cancel mr-2"></i> REJECT
                    </a>
                    <a href="javascript:void(0);" class="btn btn-success mb-2 mr-1" @click="bulkActive">
                      <i class="mdi mdi-account-check mr-2"></i> ACTIVE
                    </a>
                    <a href="javascript:void(0);" class="btn btn-pink mb-2 mr-1" @click="bulkInactive">
                      <i class="mdi mdi-account-cancel mr-2"></i> IN ACTIVE
                    </a>
                    <router-link to="/users"><a href="javascript:void(0);" class="btn btn-dark mb-2 mr-1" @click="backAction">
                      <i class="mdi mdi-arrow-left mr-2"></i> BACK
                    </a></router-link>
                  </div>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>

          </div>
        </div>
        <!-- end card -->

      </div>
    </div>
    <!-- end row -->

    <!-- end row -->
  </Layout>
</template>